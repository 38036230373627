import React, { useEffect, useState } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LayoutV2 from "../Components/LayoutV2";
import { getHospitalTermsPrivacy } from "../../../actions";
import { getInquiry } from "../../../actions/appointmentWeb/appointmentWeb.actions";
import queryString from "query-string";
import "./index.scss";

const AboutUs = () => {
  const dispatch = useDispatch();

  const hospitalGroupId = localStorage.getItem("klinikGrupId");

  const accountProfile = useSelector((state) => state.accountProfile);
  const appointmentWeb = useSelector((state) => state.appointmentWeb);

  const [inquiryData, setInquiryData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let values = {
      klinikGroupId: hospitalGroupId,
      type: "Ulasan",
      isShow: true,
      active: "1",
      sort: `[{"created_date": "ASC"}]`,
    };

    let query = queryString.stringify(values);

    dispatch(getHospitalTermsPrivacy(hospitalGroupId));
    dispatch(getInquiry(query));
  }, []);

  useEffect(() => {
    if (appointmentWeb.inquiryData.length) {
      let nested = modifyArrayIntoNested(appointmentWeb.inquiryData, 3);

      setInquiryData(nested);
    }
  }, [appointmentWeb.inquiryData]);

  const modifyArrayIntoNested = (inputArray, groupSize) => {
    let nestedArray = [];

    for (let i = 0; i < inputArray.length; i += groupSize) {
      nestedArray.push(inputArray.slice(i, i + groupSize));
    }

    return nestedArray;
  };

  return (
    <LayoutV2
      header={true}
      headerWithDropdownMenu={true}
      footer={true}
      footerCopyrightV2={true}
    >
      <Row className="margin-t-24 margin-b-16">
        <Col>
          <div className="d-flex flex-column">
            <span className="font-s-18 font-w-700 margin-b-8">
              Tentang Kami
            </span>

            <div
              dangerouslySetInnerHTML={{
                __html: accountProfile?.aboutUs,
              }}
            ></div>
          </div>
        </Col>
      </Row>

      <Row className="margin-b-8">
        <Col>
          <span className="font-s-18 font-w-700 margin-b-8">
            Ulasan Rumah Sakit
          </span>
        </Col>
      </Row>

      <Row className="d-lg-none d-md-none d-sm-none">
        <Col>
          <Carousel
            className="carousel-about-us"
            autoPlay={false}
            interval={null}
            controls={false}
          >
            {appointmentWeb.inquiryData.map((data, index) => (
              <Carousel.Item key={index}>
                <div className="about-us-card">
                  <span className="font-s-14 font-w-700 margin-b-4">
                    {data.name}
                  </span>
                  <span className="font-s-14 font-w-400">{data.desc}</span>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>

      <Row className="d-lg-flex d-sm-flex d-none">
        <Col>
          <Carousel
            className="carousel-about-us"
            autoPlay={false}
            interval={null}
            controls={false}
          >
            {inquiryData.map((data, index) => (
              <Carousel.Item key={index}>
                <Row>
                  {data.map((childData, childIndex) => (
                    <Col lg={4} key={childIndex}>
                      <div className="about-us-card">
                        <span className="font-s-14 font-w-700 margin-b-4">
                          {childData.name}
                        </span>
                        <span className="font-s-14 font-w-400">
                          {childData.desc}
                        </span>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </LayoutV2>
  );
};

export default AboutUs;
