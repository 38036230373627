import axios from "../helpers/axios";
import axiosAccountApi from "../helpers/axiosAccountApi";
import axiosCoreApi from "../helpers/axiosCoreApi";
import axiosHisApi from "../helpers/axiosHisApi";
import { accountProfileConstants } from "./constants";

export const getAllAccountProfile = (userId, offset) => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_ALL_ACCOUNTPROFILE_REQUEST });
    // const res = await axios.get(`/profiles/list/${userId}?limit=2&offset=${offset}`);
    const res = await axios.get(`/profiles/list/${userId}`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_ALL_ACCOUNTPROFILE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_ALL_ACCOUNTPROFILE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const uploadKTPFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.UPLOAD_KTP_REQUEST });
    const res = await axios.post(`/profiles/upload/identityCard`, formData);
    if (res.status === 200) {
      const { data, status } = res;
      console.log('uploadKTPFile', data)
      dispatch({
        type: accountProfileConstants.UPLOAD_KTP_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.UPLOAD_KTP_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const createProfile = (form) => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.CREATE_ACCOUNTPROFILE_REQUEST });
    const res = await axios.post(`/profiles/create`, form);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.CREATE_ACCOUNTPROFILE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.CREATE_ACCOUNTPROFILE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const editProfile = (form) => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.UPDATE_ACCOUNTPROFILE_REQUEST });
    const res = await axios.put(`/profiles/update`, form);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.UPDATE_ACCOUNTPROFILE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.UPDATE_ACCOUNTPROFILE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const editUser = (form) => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.UPDATE_USER_REQUEST });
    const res = await axios.put(`/users/updateByUUID`, form);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.UPDATE_USER_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.UPDATE_USER_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const uploadAvatarFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.UPLOAD_AVATAR_REQUEST });
    const res = await axios.post(`/users/upload/avatar`, formData);
    if (res.status === 200) {
      const { data, status } = res;
      console.log('uploadAvatarFile', data)
      dispatch({
        type: accountProfileConstants.UPLOAD_AVATAR_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.UPLOAD_AVATAR_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getRelation = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_RELATION_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/family_relations`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_RELATION_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_RELATION_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getIdentityType = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_IDENTITY_TYPE_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/identity`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_IDENTITY_TYPE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_IDENTITY_TYPE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getMarriageStatus = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_MARRIAGE_STATUS_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/marriage-status`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_MARRIAGE_STATUS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_MARRIAGE_STATUS_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getBloodType = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_BLOOD_TYPE_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/blood-type`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_BLOOD_TYPE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_BLOOD_TYPE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getEthnic = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_ETHNIC_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/ethnicities`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_ETHNIC_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_ETHNIC_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getReligion = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_RELIGION_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/religions`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_RELIGION_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_RELIGION_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getNationality = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_NATIONALITY_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/nationality`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_NATIONALITY_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_NATIONALITY_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getEducation = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_EDUCATION_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/education`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_EDUCATION_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_EDUCATION_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getJob = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_JOB_REQUEST });
    const res = await axiosHisApi.get(`/v1/api/job`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_JOB_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_JOB_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getProvince = () => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_PROVINCE_REQUEST });
    const res = await axiosCoreApi.get(`/v1/api/locations/provinces`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_PROVINCE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_PROVINCE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getCity = (provinceId) => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_CITY_REQUEST });
    const res = await axiosCoreApi.get(`/v1/api/locations/cities/${provinceId}`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_CITY_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_CITY_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getDistrict = (cityId) => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_DISTRICT_REQUEST });
    const res = await axiosCoreApi.get(`/v1/api/locations/districts/${cityId}`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_DISTRICT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_DISTRICT_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getSubdistrict = (districtId) => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_SUBDISTRICT_REQUEST });
    const res = await axiosCoreApi.get(`/v1/api/locations/subdistricts/${districtId}`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_SUBDISTRICT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_SUBDISTRICT_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getPostalCode = (subdistrictId) => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_POSTALCODE_REQUEST });
    const res = await axiosCoreApi.get(`/v1/api/locations/postalcode/${subdistrictId}`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_POSTALCODE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_POSTALCODE_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};

export const getHospitalTermsPrivacy = (hospitalGroupId) => {
  return async (dispatch) => {
    dispatch({ type: accountProfileConstants.GET_HOSPITAL_TERMS_PRIVACY_REQUEST });
    const res = await axios.get(`/hospitals/terms-privacy?hospitalGroupId=${hospitalGroupId}`);
    if (res.status === 200) {
      dispatch({
        type: accountProfileConstants.GET_HOSPITAL_TERMS_PRIVACY_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } else {
      dispatch({
        type: accountProfileConstants.GET_HOSPITAL_TERMS_PRIVACY_FAILURE,
        payload: { error: res.data.error },
      });
      return res.data;
    }
  };
};